document.addEventListener("DOMContentLoaded", function () {
  const notificationsContainer = document.getElementById('notifications');
  
  if (!notificationsContainer || !notificationsContainer.dataset.siteNotification) {
    return;
  }

  const APPEND_ITEM_SHOW_CLASS_TIME = 40;
  const REMOVE_NOTIFICATION_TIME = APPEND_ITEM_SHOW_CLASS_TIME * 12;
  const NEXT_NOTIFICATION_TIME = REMOVE_NOTIFICATION_TIME * 2;
  const LOOP_INTERVAL_TIME = NEXT_NOTIFICATION_TIME * 5;
  let index = 0;

  function showNotification() {
    const siteNotifications = JSON.parse(notificationsContainer.dataset.siteNotification);
    
    if (!siteNotifications || siteNotifications.length === 0) {
      notificationsContainer.textContent = 'お知らせはありません';
      return;
    }

    const currentItem = notificationsContainer.querySelector('.top-page_announce-section__item');
    if (currentItem) {
      removeNotification(currentItem);
    }

    setTimeout(() => {
      nextNotification(siteNotifications[index]);
      index = (index + 1) % siteNotifications.length;
    }, NEXT_NOTIFICATION_TIME);
    
    setTimeout(showNotification, LOOP_INTERVAL_TIME);
  }

  function removeNotification(currentItem) {
    currentItem.classList.remove('top-page_announce-section__item-show');
    setTimeout(() => {
      notificationsContainer.removeChild(currentItem);
    }, REMOVE_NOTIFICATION_TIME);
  }

  function nextNotification(notification) {
    const item = document.createElement('div');
    item.className = 'top-page_announce-section__item';
    // 通知の詳細ページに遷移
    const notificationLink = `/site_notifications/${notification.id}`;
    item.innerHTML = `
      <span class="top-page_announce-section__date">
        ${new Date(notification.publish_start_at).toISOString().split('T')[0]}
      </span>
      <a href="${notificationLink}" class="top-page_announce-section__info">
        ${notification.title}
      </a>
    `;
    notificationsContainer.appendChild(item);
    setTimeout(() => {
      item.classList.add('top-page_announce-section__item-show');
    }, APPEND_ITEM_SHOW_CLASS_TIME);
  }
  

  showNotification();
});
